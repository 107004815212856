import '@progress/kendo-theme-bootstrap/dist/all.css';
import 'cropperjs/dist/cropper.css';
import 'nprogress/nprogress.css';
import '~/boot/confetti';
import { VueQueryPlugin } from '@tanstack/vue-query';
import { createHead } from '@unhead/vue';
import Masonry from 'vue-next-masonry';
import DynamicLayout from '~/app/common/components/dynamic-layout.vue';
import Application from '~/Application.vue';
import { setupDaysJs } from '~/boot/daysjs';
import { setupFormKit } from '~/boot/formkit';
import { setupI18n } from '~/boot/i18n';
import { init } from '~/boot/init';
import { setupKonami } from '~/boot/konami';
import { setupSentry } from '~/boot/sentry';
import { queryClient } from '~/infrastructure/query-client';
import router from '~/infrastructure/router';
import { registerModule } from '~/kernel/registerModule';
import DateTime from '~/ui/Common/DateTime.vue';
import Loader from '~/ui/Common/Loader.vue';
import Panel from '~/ui/Common/Panel.vue';
import TheShell from '~/ui/Layout/TheShell.vue';

(async () => {
	// Register application modules
	await Promise.all([
		registerModule('admin'),
		registerModule('auth'),
		registerModule('beach'),
		registerModule('bodies'),
		registerModule('core'),
		registerModule('empowerment'),
		registerModule('legal'),
		registerModule('media'),
		registerModule('pages'),
		registerModule('players'),
		registerModule('transfers'),
		registerModule('volley'),
		registerModule('person'),
	]).catch(() => console.error('The application was not able to load all the modules'));

	// Boot the application
	const boot = init(queryClient);
	const head = createHead();

	setupDaysJs();
	setupKonami();

	// Create the application
	const app = createApp(Application);

	// Setup Global Variables
	app.config.globalProperties.appVersion = import.meta.env.VITE_APP_VERSION;
	app.config.globalProperties.sentryRelease = import.meta.env.VITE_APP_SENTRY_RELEASE;

	// Register Sentry if in production
	if (import.meta.env.PROD) {
		setupSentry(app);
	}

	// Setup Vue's Extensions
	app.use(head);
	app.use(Masonry);
	app.use(VueQueryPlugin, { queryClient });
	setupFormKit(app);
	setupI18n(app);

	// Setup Global Components
	app.component('Panel', Panel);
	app.component('Loader', Loader);
	app.component('DateTime', DateTime);
	app.component('TheShell', TheShell);
	app.component('DynamicLayout', DynamicLayout);

	// Mount the application
	boot.then(() => {
		app.use(router);
		app.mount('#app');
	});
})();
