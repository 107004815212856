import { useAuth } from '~/app/auth/composables/use-auth';
import { SpecialUser } from '~/domain/user/special-user';

export function useCanAccessEmpowerment() {
	const currentUser = useAuth();

	return computed(() => {
		const userNo = currentUser.no;

		return [
			SpecialUser.JulienRipouteau,
			SpecialUser.RomainLanz,
			SpecialUser.AlbertoRigamonti,
			SpecialUser.HiteshMalhotra,
			SpecialUser.HugoDerycke,
			SpecialUser.PatrickDeBuren,
			SpecialUser.ThomaLiechti,
			SpecialUser.TestDevFederation,
			SpecialUser.TestDevFrFederation,
		].some((no) => no === userNo);
	});
}
